<template>
    <div>
        <div class="table_content">
            <commonTitle></commonTitle>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
                     v-loading="loading">
                <el-form-item label="用户名">
                    <el-input v-model="ruleForm.username" placeholder="请输入用户名" type="username" readonly></el-input>
                </el-form-item>
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入姓名" type="name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="ruleForm.phone" placeholder="请输入联系电话" type="phone"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="submitForm('ruleForm')">修改</el-button>
<!--                    <el-button @click="resetForm()" size="small">返回</el-button>-->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import qs from "qs"
    import commonTitle from "../table/commonTitle";

    export default {
        name: "grInfo",
        components: {
            commonTitle
        },
        data() {
            return {
                url: this.$Config.base_server,
                ruleForm: {
                    id: '',
                    username: '',
                    name: '',
                    phone: '',
                    access_token: ''
                }//校验规则
                , rules: {
                    name: [
                        {required: true, message: '请输入姓名', trigger: 'change'}
                    ],
                    phone: [
                        {required: true, message: '请输入联系电话', trigger: 'change'}
                    ]
                }, loading: false
            };
        }, methods: {
            resetForm(formName) {
                //返回按钮
                window.history.back(-1);
            }, submitForm(formName) {
                this.loading = true;
                var _this = this;
                //修改密码按钮
                //立即创建按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        var token = localStorage.getItem("token");
                        $.ajax({
                            url: _this.url + "/api-public/common/updatedpInfo?access_token=" + localStorage.getItem("token"),
                            data: JSON.stringify(_this.ruleForm),
                            type: 'POST',
                            contentType: 'application/json',
                            success: function (res) {
                                if (res.resp_code == 0) {
                                    _this.loading = false;
                                    //修改成功
                                    _this.$message({
                                        message: res.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'success',
                                        duration: 1000,
                                        onClose: function () {
                                            //返回上一层路由
                                        }
                                    });
                                } else {
                                    _this.loading = false;
                                    _this.$message({
                                        message: res.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'error'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }, created() {
            //初始化数据
            var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
            //获取当前登录着的信息
            var user = JSON.parse(localStorage.getItem("user"));
            _this.ruleForm.username = user.username;
            _this.ruleForm.id = user.id;
            _this.ruleForm.name = user.nickname;
            _this.ruleForm.phone = user.phone;
        }
    }
</script>

<style scoped>
    .tab_content {
        margin-bottom: 20px;
    }

    .el-form {
        width: 600px;
        margin: 0 auto;
    }
</style>